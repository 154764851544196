<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>NOMINAS</b></h4>
          </template>
          <div class="text-right px-4 mt-3">
            <!--   -->
            <!-- <b-button v-b-modal.my-modal @click="infoForPerson(item)">Ver más</b-button> -->
            <!-- <button v-b-modal.my-modal class="mx-1 btn btn-success"><i class="fas fa-plus"></i>Agregar Devengado</button> -->
          </div>
          <!-- {{ listaItems }} -->
          <div class="table-responsive px-4 mt-3">
            <table id="datatable_nomina" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Mes</th>
                  <th>Empleados</th>
                  <th>Emitidos</th>
                  <th>Por emitir</th>
                  <th>Por corregir</th>
                  <th>Estado</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>
                    {{ $moment.tz(item.fechaInicio, "America/Costa_Rica").format("DD-MM-YYYY") + " / " + $moment.tz(item.fechaFin, "America/Costa_Rica").format("DD-MM-YYYY") }}
                  </td>
                  <td>{{ item.cantidadEmpleados }}</td>
                  <td>{{ 0 }}</td>
                  <td>{{ 0 }}</td>
                  <td>{{ 0 }}</td>
                  <td>{{ item.estado == 1 ? "Liquidado" : "0" }}</td>
                  <td>
                    <span class="pointer"
                      ><i @click="$router.push('nomina_editar/' + item.id)" class="fas fa-solid fa-pen px-1 text-success"></i>
                      <!-- <i @click="deleteRow(item.id)" class="fas fa-solid fa-trash px-1 text-danger"></i> -->
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../../config/pluginInit";
const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  data() {
    return {
      listaItems: [],
      logoEmpresa: "",
      usuario: {},
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();

      core.index();
      window.$("#datatable_nomina").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Departamento/DeleteDepartamento/" + id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "NominaLiquidacion/GetNominaLiquidadas/" + this.usuario.empresa.id,
        });
        if (res.length > 0) {
          this.listaItems = res;
          //   this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
